window.addEventListener("load", () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  for (const input of document.getElementsByTagName("input")) {
    if (input.getAttribute("name") === "time_zone") {
      input.value = timeZone;
    }
    if (input.getAttribute("name") === "proceed_to") {
      const [url, paramsString] = input.value.split("?");
      const params = new URLSearchParams(paramsString);
      params.set("time_zone", timeZone);
      input.value = url + "?" + params.toString();
    }
  }
});
